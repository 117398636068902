import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const betsApi = createApi({
	reducerPath: 'bets',
	baseQuery: baseQuery('/api/admin/v1'),
	endpoints: builder => ({
		getBetTickers: builder.query({
			query: ({endPoint}) => ({
				url: endPoint ? `bets?${endPoint}` : `bets`,
				method: 'GET'
			})
		}),
		getMatchControls: builder.query({
			query: ({endPoint}) => ({
				url: `fixtures?${endPoint}`,
				method: 'GET'
			})
		}),
		getMatchByID: builder.query({
			query: (id: string | any) => ({
				url: `fixtures/${id}`,
				method: 'get'
			})
		}),
		getBetsByID: builder.query({
			query: (id: string | any) => ({
				url: `bets/${id}`,
				method: 'get'
			})
		}),
		getSportsFilters: builder.query({
			query: () => ({
				url: `sports?dictionary=true`,
				method: 'GET'
			})
		}),

		getSports: builder.query({
			query: () => ({
				url: `sports?withStatistics=true`,
				method: 'GET'
			})
		}),

		getDashboardCards: builder.query({
			query: ({endPoint}) => ({
				url: `statistics?${endPoint}`,
				method: 'GET'
			})
		}),

		getDashboardChartData: builder.query({
			query: ({endPoint}) => ({
				url: `chart?${endPoint}`,
				method: 'GET'
			})
		}),
		getAllRequests: builder.query({
			query: ({endPoint}) => ({
				url: `payments?${endPoint}`,
				method: 'GET'
			})
		}),
		updateBetStatus: builder.mutation({
			query: ({body, id}) => {
				return {
					url: `bets/${id}`,
					method: 'PATCH',
					body
				};
			}
		})
	})
});

export const {
	useLazyGetBetTickersQuery,
	useLazyGetMatchControlsQuery,
	useLazyGetMatchByIDQuery,
	useLazyGetBetsByIDQuery,
	useLazyGetSportsFiltersQuery,
	useLazyGetSportsQuery,
	useLazyGetDashboardCardsQuery,
	useLazyGetDashboardChartDataQuery,
	useLazyGetAllRequestsQuery,
	useUpdateBetStatusMutation
} = betsApi;
