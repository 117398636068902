import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const lineConfigApi = createApi({
	reducerPath: 'lineConfig',
	baseQuery: baseQuery('/api/admin/v1'),
	endpoints: builder => ({
		getSports: builder.query({
			query: () => ({
				url: `sports?withStatistics=false`,
				method: 'GET'
			})
		}),
		getLeaguesBySport: builder.query({
			query: ({id}) => ({
				url: `leagues/sport/${id}`,
				method: 'GET'
			})
		}),
		getGamesByLeague: builder.query({
			query: ({id}) => ({
				url: `fixtures/league/${id}`,
				method: 'GET'
			})
		}),
		updateSport: builder.mutation({
			query: ({body, id}) => ({
				url: `settings/sport/${id}`,
				method: 'PATCH',
				body
			})
		}),
		updateLeague: builder.mutation({
			query: ({body, id}) => ({
				url: `settings/league/${id}`,
				method: 'PATCH',
				body
			})
		}),
		updateGame: builder.mutation({
			query: ({body, id}) => ({
				url: `settings/fixture/${id}`,
				method: 'PATCH',
				body
			})
		})
	})
});

export const {
	useLazyGetSportsQuery,
	useLazyGetLeaguesBySportQuery,
	useLazyGetGamesByLeagueQuery,
	useUpdateSportMutation,
	useUpdateLeagueMutation,
	useUpdateGameMutation
} = lineConfigApi;
