export const authorizationTypes = {
	AUTHORIZED: 'authorized',
	UNAUTHORIZED: 'unauthorized',
	UNDEFINED: 'undefined'
};

export enum gameStatusEnum {
	LIVE = 'LIVE',
	PREMATCH = 'PREMATCH'
}

export enum betTypeEnum {
	MONEYLINE = 'MONEYLINE',
	SPREAD = 'SPREAD',
	TOTAL = 'TOTAL',
	TEAM_TOAL = 'TEAM_TOAL',
	SPECIAL = 'SPECIAL'
}

export enum playersBetTypeEnum {
	MONEYLINE = 'Moneyline',
	TEAM_TOTAL = 'TeamTotal',
	SPREAD = 'Spread',
	TOTAL = 'Total',
	SPECIAL = 'Special'
}

export enum periodEnum {}

export enum currencyEnum {
	EUR = 'EUR',
	USD = 'USD',
	BTC = 'BTC',
	ETH = 'ETH',
	LTC = 'LTC',
	AMD = 'AMD'
}

export enum accomplishedBetStatusEnum {
	ACCEPTED = 'ACCEPTED',
	NOT_ACCEPTED = 'NOT_ACCEPTED',
	NOT_FOUND = 'NOT_FOUND',
	RATE_CHANGE = 'RATE_CHANGED',
	CANCELED = 'CANCELED',
	ABOVE_MAX_BET = 'ABOVE_MAX_BET',
	LOST = 'LOST',
	REFUNDED = 'REFUNDED',
	WON = 'WON',
	UNSETTLED = 'UNSETTLED',
	PARTIAL_LOST = 'PARTIAL_LOST',
	PARTIAL_WON = 'PARTIAL_WON'
}

export enum betStatusEnum {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED'
}

export enum playersPageTypesEnum {
	PROFILE = 'PROFILE - KYC',
	BETS = 'BETS',
	DEPOSITS = 'DEPOSITS',
	WITHDRAWALS = 'WITHDRAWALS',
	TRANSACTIONS = 'TRANSACTIONS',
	MESSAGING = 'MESSAGING'
}

export enum settingPageTypesEnum {
	LINE_CONFIG = 'LINE CONFIG.',
	TRANSLATION = 'TRANSLATION',
	CURRENCIES = 'CURRENCIES',
	WEBSITE = 'WEBSITE'
}

export enum filterTypesEnum {
	TEXT = 'TEXT',
	SELECT = 'SELECT',
	DATE_RANGE = 'DATE_RANGE',
	DATE = 'DATE',
	CHECKBOX = 'CHECKBOX',
	NUMBER_INPUT = 'NUMBER_INPUT'
}

export enum TransactionStatusEnum {
	PENDING = 'Pending',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	ABORTED = 'Aborted'
}

export enum PaymentStatusEnum {
	APPROVED = 'Completed',
	DECLINED = 'Failed',
	PENDING = 'Pending'
}

export enum changedBetStatusEnum {
	CANCELED = 'CANCELED',
	LOSS = 'LOSS',
	WIN = 'WIN',
	UNSETTLED = 'UNSETTLED',
	PUSH = 'VOID'
}

export const oddTypes = {
	DECIMAL: 'Decimal',
	AMERICAN_ODD: 'americanOdd'
};

export const homeTeamType = {
	TEAM1: 'Team1',
	TEAM2: 'Team2',
	DRAW: 'Draw'
};

export const betTypes = {
	HANDICAP: 'Spread',
	TOTAL: 'Total',
	MONEYLINE: 'Moneyline',
	TEAM_TOTAL: 'TeamTotal',
	SPECIAL_FIXTURE: 'Special',
	LEAGUE_SPECIAL: 'Special'
};

export enum VerificationTypeEnum {
	IDENTITY = 'identity',
	ADDRESS = 'address',
	BANK_CARD = 'bankCard'
}

export enum TransactionTypesEnum {
	DEPOSIT = 'Deposit',
	WITHDRAWAL = 'Withdrawal',
	WAGER = 'Wager',
	PROFIT = 'Profit',
	REFUND = 'Refund'
}

export const breakpoint = {
	HAMBURGER_MENU_SCREEN: 1100,
	MIDDLE_SCREEN: 950,
	PERSONAL_DETAILS_BREAKPOINT: 900,
	TABLET: 768,
	MOBILE: 600
};

export type AcceptedCurrencies = 'BTC' | 'LTC' | 'ETH' | 'AMD' | 'USD' | 'RUB';
