import userIcon from 'assets/icons/userIcon.png';
import '../Header.less';
import {Dropdown, MenuProps} from 'antd';
import {useLogOutMutation} from 'store/services/authApi';
import {authorizationTypes} from 'types';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {setUser} from 'store/slices/authSlice';

const LogOut = () => {
	const [logOut, {}] = useLogOutMutation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = async () => {
		dispatch(setUser({isAuthenticated: authorizationTypes.UNAUTHORIZED, userCredentials: null}));
		try {
			navigate('/');
			await logOut(undefined).unwrap();
			toast.success('Successfully logged  out');
		} catch (e) {
			console.error(e);
		}
	};

	const items: MenuProps['items'] = [
		{
			key: 'logOut',
			label: <div onClick={handleLogout}>Log out</div>
		}
	];

	return (
		<Dropdown menu={{items}} className="edit-dropdown-wrapper" placement="bottom">
			<img src={userIcon} alt="userIcon" className="user-icon" />
		</Dropdown>
	);
};

export default LogOut;
