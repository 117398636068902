import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';
import {authApi} from './services/authApi';
import userAuthReducer from './slices/authSlice';
import {betsApi} from './services/betsApi';
import {playersApi} from './services/playersApi';
import {lineConfigApi} from './services/lineConfigApi';
import {webstiteApi} from './services/websiteApi';

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[betsApi.reducerPath]: betsApi.reducer,
		[playersApi.reducerPath]: playersApi.reducer,
		[lineConfigApi.reducerPath]: lineConfigApi.reducer,
		[webstiteApi.reducerPath]: webstiteApi.reducer,
		userAuth: userAuthReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([
			authApi.middleware,
			betsApi.middleware,
			playersApi.middleware,
			lineConfigApi.middleware,
			webstiteApi.middleware
		])
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
