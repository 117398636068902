import {createSlice} from '@reduxjs/toolkit';
import {authorizationTypes} from 'types';
import {UserCredentialProps} from 'views/Players/tabContents/PersonalDetails/PersonalDetails';

export const setUserCredentials = (userCredentialsData: {payload: UserCredentialProps}) => {
	const findMainBalance =
		userCredentialsData?.payload?.balances.find(balance => balance.isMain)?.amount || '0.00';
	const mainBalance = !isNaN(+findMainBalance) ? findMainBalance : '0.00';

	const mainCurrency = userCredentialsData?.payload?.balances?.find(balance => balance.isMain)?.currency;

	return {
		active: userCredentialsData?.payload?.active,
		address1: userCredentialsData?.payload?.address1,
		address2: userCredentialsData?.payload?.address2,
		city: userCredentialsData?.payload?.city,
		country: userCredentialsData?.payload?.country,
		currency: mainCurrency,
		email: userCredentialsData?.payload?.email,
		firstName: userCredentialsData?.payload?.firstName,
		id: userCredentialsData?.payload?.id,
		lastName: userCredentialsData?.payload?.lastName,
		phoneNumber: userCredentialsData?.payload?.phoneNumber,
		dateOfBirth: userCredentialsData?.payload?.dateOfBirth,
		nationalIdentificationId: userCredentialsData?.payload?.nationalIdentificationId,
		postalCode: userCredentialsData?.payload?.postalCode,
		role: userCredentialsData?.payload?.role,
		clientId: userCredentialsData?.payload?.clientId,
		balance: mainBalance,
		emailConfirmed: userCredentialsData?.payload?.emailConfirmed
	};
};

const authSlice = createSlice({
	name: 'userAuth',
	initialState: {isAuthenticated: authorizationTypes.UNDEFINED, userCredentials: {email: '', password: ''}},
	reducers: {
		setUser(state, action) {
			return {
				...state,
				isAuthenticated: action.payload.isAuthenticated,
				userCredentials: action.payload.userCredentials
			};
		}
	}
});

export const {setUser} = authSlice.actions;
export default authSlice.reducer;
