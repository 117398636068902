import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const playersApi = createApi({
	reducerPath: 'players',
	baseQuery: baseQuery('/api/admin/v1'),
	endpoints: builder => ({
		getPlayers: builder.query({
			query: ({endPoint}) => ({
				url: `users?${endPoint}`,
				method: 'GET'
			})
		}),
		getUserBets: builder.query({
			query: ({endPoint}) => ({
				url: endPoint ? `bets?${endPoint}` : `bets`,
				method: 'GET'
			})
		}),
		getUserTransactions: builder.query({
			query: ({endPoint}) => ({
				url: endPoint ? `transactions?${endPoint}` : `transactions`,
				method: 'GET'
			})
		}),
		getUserInfo: builder.query({
			query: ({endPoint}) => ({
				url: `user/${endPoint}`,
				method: 'GET'
			})
		}),
		getUserVerifications: builder.query({
			query: ({endPoint}) => ({
				url: `verifications?userId=${endPoint}`,
				method: 'GET'
			})
		}),
		updateUserDetails: builder.mutation({
			query: ({body, endPoint}) => {
				return {
					url: `user/${endPoint}`,
					method: 'PATCH',
					body
				};
			}
		}),
		updateUserVerifications: builder.mutation({
			query: ({body, endPoint}) => {
				return {
					url: `verifications/${endPoint}`,
					method: 'PATCH',
					body
				};
			}
		}),
		inbox: builder.query({
			query: ({endPoint}) => ({
				url: `messages?${endPoint}`,
				method: 'GET'
			})
		}),
		writeMessage: builder.mutation({
			query: body => ({
				url: 'messages',
				method: 'POST',
				body
			})
		}),
		getUserDeposits: builder.query({
			query: ({endPoint}) => ({
				url: `payments?${endPoint}`,
				method: 'GET'
			})
		}),
		getUserWithdrawals: builder.query({
			query: ({endPoint}) => ({
				url: `payments?${endPoint}`,
				method: 'GET'
			})
		}),
		updatePaymentStatus: builder.mutation({
			query: ({id, endPoint}) => {
				return {
					url: `payments/${id}/${endPoint}`,
					method: 'PATCH'
					// body
				};
			}
		}),
		userDepositRequest: builder.mutation({
			query: ({body}) => ({
				url: `transactions`,
				method: 'POST',
				body
			})
		}),
		userDeductionRequest: builder.mutation({
			query: ({body}) => ({
				url: `withdraw`,
				method: 'POST',
				body
			})
		})
	})
});

export const {
	useLazyGetPlayersQuery,
	useLazyGetUserBetsQuery,
	useLazyGetUserTransactionsQuery,
	useLazyGetUserInfoQuery,
	useLazyGetUserVerificationsQuery,
	useUpdateUserDetailsMutation,
	useUpdateUserVerificationsMutation,
	useLazyInboxQuery,
	useWriteMessageMutation,
	useLazyGetUserDepositsQuery,
	useLazyGetUserWithdrawalsQuery,
	useUpdatePaymentStatusMutation,
	useUserDepositRequestMutation,
	useUserDeductionRequestMutation
} = playersApi;
