import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const authApi = createApi({
	reducerPath: 'auth',
	baseQuery: baseQuery('/api/admin/v1'),
	endpoints: builder => ({
		logIn: builder.mutation({
			query: (body: {email: string; password: string}) => ({
				url: 'auth/sign-in',
				method: 'POST',
				body
			})
		}),
		logOut: builder.mutation({
			query: () => ({
				url: 'auth/sign-out',
				method: 'POST'
			})
		}),
		resetPasswordPermission: builder.mutation({
			query: body => ({
				url: 'auth/password-reset',
				method: 'POST',
				body
			})
		}),
		resetPassword: builder.mutation({
			query: body => ({
				url: 'auth/password-recover',
				method: 'POST',
				body
			})
		}),
		getUserInfo: builder.query({
			query: () => ({
				url: 'admin-info',
				method: 'GET'
			})
		})
	})
});

export const {
	useLogInMutation,
	useLogOutMutation,
	useResetPasswordPermissionMutation,
	useResetPasswordMutation,
	useLazyGetUserInfoQuery
} = authApi;
