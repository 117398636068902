export function formatDateNumeric(inputDate: string, type?: string): string {
	const date = new Date(inputDate);

	const day = date.getUTCDate().toString().padStart(2, '0');
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
	const year = date.getUTCFullYear().toString();

	const hours = date.getUTCHours().toString().padStart(2, '0');
	const minutes = date.getUTCMinutes().toString().padStart(2, '0');
	const seconds = date.getUTCSeconds().toString().padStart(2, '0');

	const result =
		type === 'single'
			? `${year}-${month}-${day}`
			: `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
	return result;
}

export function formatDateString(date: string | any) {
	if (date) {
		let formattedDate = new Date(date);
		formattedDate.setHours(0, 0, 0, 0);
		return formattedDate.toISOString();
	}
	return null;
}

export const dateFormatOnlyNumbers = (date: string) => {
	const parsedDate = new Date(date);
	const year = parsedDate.getFullYear();
	const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
	const day = parsedDate.getDate().toString().padStart(2, '0');
	const hours = parsedDate.getHours().toString().padStart(2, '0');
	const minutes = parsedDate.getMinutes().toString().padStart(2, '0');

	// return `${month}.${day}.${year}, ${hours}:${minutes}`;
	return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const dateFormatFullDate = (date: string) => {
	const parsedDate = new Date(date);
	const year = parsedDate.getFullYear();
	const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
	const day = parsedDate.getDate().toString().padStart(2, '0');
	const hours = parsedDate.getHours().toString().padStart(2, '0');
	const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
	const seconds = parsedDate.getSeconds().toString().padStart(2, '0');

	// return `${month}.${day}.${year}, ${hours}:${minutes}`;
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function formatDateOnlyYear(inputDate: string, type?: string): string {
	const date = new Date(inputDate);

	const year = date.getUTCFullYear().toString();

	const result = `${year}`;
	return result;
}

export const dateFormatOnlyTime = (date: string) => {
	const parsedDate = new Date(date);
	const hours = parsedDate.getHours().toString().padStart(2, '0');
	const minutes = parsedDate.getMinutes().toString().padStart(2, '0');

	return `${hours}:${minutes}`;
};
