import {ReactNode} from 'react';
import './Layout.less';
import Header from './Header';
import {authorizationTypes} from 'types';

interface LayoutInterface {
	children: ReactNode;
	isAuthenticated?: string;
}

const Layout = ({children, isAuthenticated}: LayoutInterface) => {
	return (
		<div className="layout-wrapper">
			{isAuthenticated == authorizationTypes.AUTHORIZED && <Header />}
			<div className="layout-main-action-wrapper">{children}</div>
		</div>
	);
};

export default Layout;
