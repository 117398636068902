import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const webstiteApi = createApi({
	reducerPath: 'webstite',
	baseQuery: baseQuery('/api/admin/v1'),
	endpoints: builder => ({
		getWebsiteData: builder.query({
			query: () => ({
				url: `settings/website`,
				method: 'GET'
			})
		}),

		updateWebsiteData: builder.mutation({
			query: ({body}) => {
				return {
					url: `settings/website`,
					method: 'PATCH',
					body
				};
			}
		})
	})
});

export const {useLazyGetWebsiteDataQuery, useUpdateWebsiteDataMutation} = webstiteApi;
