import {useEffect, useState} from 'react';
import {dateFormatFullDate} from 'utils/helpers/formatDateNumeric';

export interface DateFormatOptions {
	year?: 'numeric' | '2-digit';
	month?: 'numeric' | '2-digit';
	day?: 'numeric' | '2-digit';
	hour?: 'numeric' | '2-digit';
	minute?: 'numeric' | '2-digit';
	second?: 'numeric' | '2-digit';
}

const CurrentDateTime = () => {
	const [currentDate, setCurrentDate] = useState(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const options: DateFormatOptions = {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	};

	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);

	const date = dateFormatFullDate(formattedDate);

	const formattedTimeZone = ` (GMT + 04:00)`;
	const formattedResult = `${date.replace(',', '  ').replace('PM', '')}${formattedTimeZone}`;

	return <div>{formattedResult}</div>;
};

export default CurrentDateTime;
