import {Button, Divider, Menu, Tabs, TabsProps} from 'antd';
import {useEffect, useState} from 'react';
import PAGE_LOGO from 'assets/logos/tangerbet-logo.svg';
import './Header.less';
import {useNavigate} from 'react-router-dom';
import CurrentDateTime from './components/CurrentDateTime';
import LoginForm from './components/LoginForm';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {authorizationTypes, breakpoint} from 'types';
import LogOut from './components/LogOut';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {MenuFoldOutlined, MenuUnfoldOutlined, CloseOutlined} from '@ant-design/icons';

const items: TabsProps['items'] = [
	{
		key: 'dashboard',
		label: `DASHBOARD`
	},
	{
		key: 'bet-ticker',
		label: `BET TICKER`
	},
	{
		key: 'bet-control',
		label: `BETS CONTROL`
	},
	{
		key: 'match-control',
		label: `MATCH CONTROL`
	},
	{
		key: 'players',
		label: `PLAYERS`
	},
	{
		key: 'settings',
		label: `SETTINGS`
	},
	{
		key: 'messaging',
		label: `MESSAGING`
	},
	{
		key: 'requests',
		label: `REQUESTS`
	}
];

const Header = () => {
	const [tab, setTab] = useState<string>(window.location.pathname.split('/')[1]);
	const navigate = useNavigate();
	const screenWidth = useScreenWidth();
	const [collapsed, setCollapsed] = useState(true);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const onChange = (key: string) => {
		setTab(key);
		toggleCollapsed();
		// if (key === 'dashboard') navigate('/');
		navigate(`/${key}`);
	};

	useEffect(() => {
		const path = window.location.pathname.split('/')[1];
		if (path) {
			setTab(path);
		} else {
			setTab('dashboard');
		}
	}, []);

	const onLogoClick = () => {
		navigate('/dashboard');
		setTab('dashboard');
	};

	useEffect(() => {
		window.location.pathname.split('/')[1];
	}, []);

	return (
		<div className="header-wrapper">
			{screenWidth > breakpoint.HAMBURGER_MENU_SCREEN ? (
				<div className="header-tabs-timer-wrapper">
					<div className="header-logo-tabs-wrapper">
						<img
							src={PAGE_LOGO}
							alt="page-logo"
							onClick={onLogoClick}
							style={{cursor: 'pointer'}}
						/>
						<Tabs
							items={
								isAuthenticated === authorizationTypes.AUTHORIZED
									? (items as any)
									: [
											{
												key: 'dashboard',
												label: `DASHBOARD`
											}
									  ]
							}
							defaultActiveKey={tab}
							onChange={onChange}
							activeKey={tab}
						/>
					</div>
					<div className="header-timer-block">
						<CurrentDateTime />
						{/* <LoginForm /> */}
						<LogOut />
					</div>
				</div>
			) : (
				<div className="mobile-header-tabs-timer-wrapper">
					<div
						className={`mobile-header-logo-tabs-wrapper ${
							!collapsed && 'mobile-header-menu-open'
						}`}>
						<div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
							<img
								src={PAGE_LOGO}
								alt="page-logo"
								onClick={onLogoClick}
								style={{cursor: 'pointer'}}
							/>
							{collapsed ? (
								<Button
									type="primary"
									onClick={toggleCollapsed}
									className={`hamburger-button `}>
									{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
								</Button>
							) : (
								<CloseOutlined onClick={toggleCollapsed} style={{color: '#ffffff'}} />
							)}
						</div>
						{!collapsed && (
							<Menu
								defaultSelectedKeys={['1']}
								defaultOpenKeys={['sub1']}
								mode="inline"
								theme="dark"
								inlineCollapsed={collapsed}
								// items={items}
							>
								<Tabs
									items={
										isAuthenticated === authorizationTypes.AUTHORIZED
											? (items as any)
											: [
													{
														key: 'dashboard',
														label: `DASHBOARD`
													}
											  ]
									}
									defaultActiveKey={tab}
									onChange={onChange}
									activeKey={tab}
									style={{display: 'flex', flexDirection: 'column'}}
								/>
							</Menu>
						)}
					</div>
				</div>
			)}
			<Divider className="header-divider" />
		</div>
	);
};

export default Header;
