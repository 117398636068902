import {Button, ConfigProvider, Result} from 'antd';
import Layout from 'components/Layout';
import LoaderSimple from 'components/Loader/Loader';
import {PrivateRoute} from 'components/PrivateRoute';
import {Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {routes} from 'routes';
import {RootState} from 'store';
import './App.less';
import {useLazyGetUserInfoQuery} from 'store/services/authApi';
import {setUser, setUserCredentials} from 'store/slices/authSlice';
import {authorizationTypes} from 'types';

const App = () => {
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);
	const dispatch = useDispatch();
	const [getUserInfo, {data}] = useLazyGetUserInfoQuery();
	const location = useLocation();

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userCredentialsData = await getUserInfo({}).unwrap();
				const modifiedUserCredentials = setUserCredentials(userCredentialsData);
				dispatch(
					setUser({
						isAuthenticated: authorizationTypes.AUTHORIZED,
						userCredentials: modifiedUserCredentials
					})
				);
			} catch (e) {
				dispatch(setUser({isAuthenticated: authorizationTypes.UNAUTHORIZED, userCredentials: null}));
				navigate('/');
			}
		};
		fetchData();
		navigate(location.pathname);
	}, []);

	return (
		<div className="App">
			<ConfigProvider popupMatchSelectWidth={false}>
				<Layout isAuthenticated={isAuthenticated}>
					<Suspense fallback={<LoaderSimple />}>
						<Routes>
							{routes.map((route: any, index) => {
								return (
									<Route key={`route-key-${route.path}-${index}`}>
										{
											<Route
												key={route.path}
												path={route.path}
												element={
													route.privateRoute ? (
														<PrivateRoute isAuthenticated={isAuthenticated}>
															{route.element && <route.element />}
														</PrivateRoute>
													) : (
														route.element && <route.element />
													)
												}
											/>
										}
										{route.subMenu?.map((route: any) => (
											<Route
												key={route.path}
												path={route.path}
												element={
													route.privateRoute ? (
														<PrivateRoute isAuthenticated={isAuthenticated}>
															{route.element && <route.element />}
														</PrivateRoute>
													) : (
														route.element && <route.element />
													)
												}
											/>
										))}
									</Route>
								);
							})}

							<Route
								path="*"
								element={
									<Result
										status="404"
										title="404"
										subTitle="Sorry, the page you visited does not exist."
										style={{margin: '0 auto', color: 'white'}}
										className="not-found-page"
										extra={
											<Button type="primary" onClick={() => navigate('/')}>
												Back Home
											</Button>
										}
									/>
								}
							/>
						</Routes>
					</Suspense>
				</Layout>
			</ConfigProvider>
		</div>
	);
};

export default App;
