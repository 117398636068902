import React from 'react';

export const routes = [
	{
		path: '/',
		element: React.lazy(() => import('views/LoginForms')),
		privateRoute: false
	},
	{
		path: '/auth/password-reset',
		element: React.lazy(() => import('views/ConfirmPassword')),
		privateRoute: false
	},
	{
		path: '/resetPassword',
		element: React.lazy(() => import('views/ResetPassword')),
		privateRoute: false
	},
	{
		path: '/dashboard',
		element: React.lazy(() => import('views/Dashboard')),
		privateRoute: true
	},
	{
		path: '/bet-ticker',
		element: React.lazy(() => import('views/BetTicker')),
		privateRoute: true
	},
	{
		path: '/bet-control',
		element: React.lazy(() => import('views/BetsControl')),
		privateRoute: true
	},
	{
		path: '/match-control',
		element: React.lazy(() => import('views/MatchControl')),
		privateRoute: true,
		subMenu: [
			{
				path: '/match-control/:id',
				element: React.lazy(() => import('views/MatchControl')),
				privateRoute: true
			}
		]
	},
	{
		path: '/players',
		element: React.lazy(() => import('views/Players')),
		privateRoute: true,
		subMenu: [
			{
				path: '/players/:path',
				element: React.lazy(() => import('views/Players')),
				privateRoute: true
			}
		]
	},
	{
		path: '/settings',
		element: React.lazy(() => import('views/Setting')),
		privateRoute: true
	},
	{
		path: '/messaging',
		element: React.lazy(() => import('views/Messagings')),
		privateRoute: true
	},
	{
		path: '/requests',
		element: React.lazy(() => import('views/Requests')),
		privateRoute: true
	}
];
